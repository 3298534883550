// src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/Header';
import HomePage from './components/HomePage';
import Datenschutz from './components/Datenschutz';
import Impressum from './components/Impressum';
import ScrollToTop from './components/ScrollToTop';

function App() {
  return (
    <Router>
      <ScrollToTop />
      <Header />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/datenschutz" element={<Datenschutz />} />
        {/* Add more routes as needed */}
      </Routes>
      <Impressum />
    </Router>
  );
}

export default App;
