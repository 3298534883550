// src/components/HomePage.js
import React from 'react';
import HeroSection from './HeroSection';
import OpeningHours from './OpeningHours';
import InfoAnfang from './InfoAnfang';
import UrlaubsFormular from './UrlaubsFormular';
import KontaktTermine from './KontaktTermine';
import Teamsection from './Teamsection';
import Anfahrt from './Anfahrt';
import PraxisInfo from './PraxisInfo';
import Labors from './Labors';


const HomePage = () => {
  return (
    <>
      <HeroSection />
      <div className="container my-5">
        <OpeningHours id="openingHours" />
        <InfoAnfang id="infoAnfang" />
        <UrlaubsFormular id="urlaubsFormular" />
        <KontaktTermine id="kontakt" />
        <Teamsection id="team" />
        <Anfahrt id="anfahrt" />
        <PraxisInfo id="praxisInfo" />
        <Labors id="labors" />
       
      </div>
    </>
  );
};

export default HomePage;
