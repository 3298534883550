import React from 'react';
import { useNavigate } from 'react-router-dom';
import './Datenschutz.css';

const Datenschutz = () => {
  const navigate = useNavigate();

  return (
    <section className="datenschutz py-5">
      <div className="container">
        <h1 className="mb-4">Datenschutzerklärung</h1>

        <h2>1. Allgemeine Hinweise und Pflichtinformationen</h2>
        <p>
          Der Schutz Ihrer persönlichen Daten ist uns ein besonderes Anliegen. Wir verarbeiten Ihre Daten
          ausschließlich auf Grundlage der gesetzlichen Bestimmungen (DSGVO, TMG usw.). Nachfolgend informieren
          wir Sie über die wichtigsten Aspekte der Datenverarbeitung im Rahmen unserer Webseite.
        </p>

        <h2>2. Verantwortlicher</h2>
        <p>
          Verantwortlich für die Datenverarbeitung auf dieser Website ist:
          <br />
          Dr. Monika Gadner<br />
          Fachärztin für Gynäkologie und Geburtshilfe<br />
          Weidlingerstrasse 4/4<br />
          3400 Klosterneuburg<br />
          Tel.: 02243/22380<br />
          E-Mail: <a href="mailto:office@gadner.com">office@gadner.com</a>
        </p>

        <h2>3. Erhebung und Speicherung personenbezogener Daten sowie Art und Zweck ihrer Verwendung</h2>
        <p>
          Auf unserer Webseite werden nur die Daten erhoben, die für die Bereitstellung unserer Dienste notwendig
          sind. Dazu gehören:
        </p>
        <h3>Kontaktanfragen / Terminvereinbarungen</h3>
        <p>
          Wenn Sie über ein Kontaktformular mit uns in Verbindung treten oder einen Termin vereinbaren, werden die
          von Ihnen eingegebenen Daten (z. B. Name, E-Mail-Adresse, Telefonnummer, Terminwunsch) gespeichert, um Ihre
          Anfrage zu bearbeiten und mit Ihnen in Kontakt zu treten. Die Verarbeitung erfolgt auf Grundlage Ihrer
          Einwilligung gemäß Art. 6 Abs. 1 lit. a DSGVO.
        </p>
        <h3>Server-Logfiles</h3>
        <p>
          Bei jedem Zugriff auf unsere Webseite werden automatisch Informationen in sogenannten Server-Logfiles
          gespeichert (z. B. IP-Adresse, Datum, Zugriffszeit, Browsertyp, Betriebssystem). Diese Daten dienen der
          Sicherheit und der Optimierung unserer Website und werden – sofern keine gesetzliche Pflicht zur Speicherung
          besteht – nach kurzer Zeit wieder gelöscht.
        </p>
        <h3>Cookies</h3>
        <p>
          Unsere Webseite verwendet Cookies, um das Nutzererlebnis zu verbessern (z. B. um wiederkehrende Nutzer zu
          identifizieren oder um die Navigation zu erleichtern). Hierbei handelt es sich in der Regel um so
          genannte „Session-Cookies“, die nach Schließen Ihres Browsers wieder gelöscht werden. Eine detaillierte
          Übersicht über die verwendeten Cookies finden Sie in unserer Cookie-Richtlinie.
        </p>

        <h2>4. Weitergabe von Daten</h2>
        <p>
          Eine Weitergabe Ihrer personenbezogenen Daten an Dritte erfolgt nur dann, wenn dies zur Erfüllung unserer
          Leistungen notwendig ist, wir gesetzlich dazu verpflichtet sind oder Sie in die Datenweitergabe eingewilligt
          haben. Eine Weitergabe an Dritte zu Werbezwecken findet nicht statt.
        </p>

        <h2>5. Datensicherheit</h2>
        <p>
          Wir setzen technische und organisatorische Sicherheitsmaßnahmen ein, um Ihre durch uns verarbeiteten Daten
          gegen zufällige oder vorsätzliche Manipulationen, Verlust, Zerstörung oder gegen den Zugriff unberechtigter
          Personen zu schützen. Unsere Sicherheitsmaßnahmen werden entsprechend der technologischen Entwicklung
          fortlaufend verbessert.
        </p>

        <h2>6. Betroffenenrechte</h2>
        <p>
          Sie haben das Recht auf Auskunft über Ihre bei uns gespeicherten personenbezogenen Daten sowie das Recht auf
          Berichtigung, Löschung, Einschränkung der Verarbeitung, Widerspruch gegen die Verarbeitung und das Recht
          auf Datenübertragbarkeit. Sollten Sie Fragen zur Erfassung, Verarbeitung oder Nutzung Ihrer personenbezogenen
          Daten haben, oder von Ihren Rechten Gebrauch machen wollen, kontaktieren Sie uns bitte über die oben genannten
          Kontaktdaten.
        </p>

        <h2>7. Widerspruchsrecht bei Direktwerbung</h2>
        <p>
          Falls Ihre Daten für Zwecke der Direktwerbung verarbeitet werden, haben Sie jederzeit das Recht, der Verarbeitung
          Ihrer personenbezogenen Daten zu widersprechen. Den Widerspruch können Sie schriftlich oder per E-Mail an uns
          richten.
        </p>

        <h2>8. Aktualität und Änderung dieser Datenschutzerklärung</h2>
        <p>
          Diese Datenschutzerklärung ist aktuell gültig und datiert vom [Datum einsetzen]. Aufgrund gesetzlicher Vorgaben
          kann es erforderlich sein, diese Datenschutzerklärung zu ändern. Die jeweils aktuelle Version ist auf unserer
          Webseite verfügbar.
        </p>

        <h2>9. Externe Links</h2>
        <p>
          Unsere Webseite enthält Links zu anderen Websites. Bitte beachten Sie, dass diese Datenschutzerklärung nur für
          unsere Website gilt. Für die Inhalte und die Datenschutzerklärungen der verlinkten Seiten sind ausschließlich
          deren Betreiber verantwortlich.
        </p>

        <button
            onClick={() => {
                window.scrollTo({ top: 0, behavior: 'smooth' });
                navigate(-1);
            }}
            className="back-button"
            >
            Zurück
            </button>

      </div>
    </section>
  );
};

export default Datenschutz;
