// src/components/ImpressumFooter.js
import React from 'react';
import { Link } from 'react-router-dom';
import Datenschutz from './Datenschutz'; // You might not even need to import this here unless used elsewhere
import './Impressum.css';

const Impressum = () => {
  return (
    <footer className="impressum-footer">
      <div className="container">
        <h2 className="footer-title2 m-0">Impressum</h2>
        <p>
          <strong>Dr. Monika Gadner</strong> – Fachärztin für Gynäkologie und
          Geburtshilfe, Weidlingerstrasse 4/4, 3400 Klosterneuburg, Tel:
          02243/22380, E-Mail: <a href="mailto:office@gadner.com">office@gadner.com</a>
        </p>
        <p>
          <strong>Berufsbezeichnung:</strong> Fachärztin für Gynäkologie und Geburtshilfe, verliehen in
          Österreich | <strong>Kammerzugehörigkeit:</strong> Ärztekammer Niederösterreich (
          <a href="http://www.arztnoe.at" target="_blank" rel="noopener noreferrer">www.arztnoe.at</a>)
        </p>
        <p>
          <strong>Aufsichtsbehörde:</strong> Bezirkshauptmannschaft Tulln (
          <a href="https://www.noe.gv.at/noe/Tulln/Bezirkshauptmannschaft_Tulln.html" target="_blank" rel="noopener noreferrer">
            www.noe.gv.at
          </a>)
        </p>
        <p>
          <strong>Berufliche Vorschrift:</strong> Ärztegesetz 1998 (
          <a href="http://www.ris.bka.gv.at" target="_blank" rel="noopener noreferrer">Link</a>)
        </p>
        <p>
          <strong>Homepage:</strong> © {new Date().getFullYear()} Text & Inhalt: Dr. Monika Gadner | Design & Entwicklung: Nicolas Gadner | Fotos: Marie Jorunn Ruhsam – Alle Inhalte dieser Website sind urheberrechtlich geschützt.
        </p>
        <p>
          <strong>Datenschutz:</strong> Informationen gemäß Art. 13 DSGVO (
          <a href="https://www.arztnoe.at/dsgvo" target="_blank" rel="noopener noreferrer">Link</a>) &nbsp;
          <strong>Datenschutzerklärung:</strong> <Link to="/datenschutz" className="footer-link">Hier</Link>
        </p>
        <p>
          <strong>Haftungsausschluss:</strong> Trotz sorgfältiger inhaltlicher Kontrolle übernehmen wir keine Haftung für die Inhalte externer Links. Für den Inhalt der verlinkten Seiten sind ausschließlich deren Betreiber verantwortlich.
        </p>
      </div>
    </footer>
  );
};

export default Impressum;
